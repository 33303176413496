import * as React from 'react';
import { graphql } from 'gatsby';
import { InView } from 'react-intersection-observer';
import { Layout } from 'components/Layout';
import { SEO } from 'components/SEO';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { ProductCard } from 'components/ProductCard';
import { AsyncCatalog } from 'components/AsyncCatalog';
import { useGlobal } from 'components/hooks';

type Props = {
  readonly data: GatsbyTypes.CategoryPageQuery;
};

const CategoryPage = ({ data }: Props) => {
  const c = data.api.category;

  if (!c) {
    return null;
  }

  const [, globalActions] = useGlobal(() => null);

  React.useEffect(() => {
    return () => globalActions.setVisibleCategoryState(c.rowId, false);
  }, [c.rowId]);

  const handleInView = (inView: boolean) => {
    globalActions.setVisibleCategoryState(c.rowId, inView);
  };

  const breadcrumbs = [
    {
      label: 'Каталог',
      url: '/catalog',
    },
  ];

  return (
    <Layout>
      <SEO title={c.name} />
      <div className="pb-8 border-b border-gray-90 mb-16">
        <div className="pc-row">
          <Breadcrumbs items={breadcrumbs} />
          <InView as="div" rootMargin="-49px 0px -65px 0px" onChange={handleInView} id={`category-row-${c.rowId}`}>
            <h1 className="text-black text-4xl font-bold leading-snug mb-10">{c.name}</h1>
            <div className="pc-free-items">
              {c.products.map((p) => (
                <div className="pc-free-item" key={p.rowId}>
                  <ProductCard data={p} />
                </div>
              ))}
            </div>
          </InView>
        </div>
      </div>
      <AsyncCatalog skipCategoryId={c.rowId} />
    </Layout>
  );
};

export const query = graphql`
  query CategoryPage($rowId: Int!) {
    api {
      category(rowId: $rowId) {
        rowId
        name
        products {
          rowId
          ...ProductCard_data
        }
      }
    }
  }
`;

export default CategoryPage;
